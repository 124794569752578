body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100dvh;
  @extend .font-calibri;
  // #app-global-bg {
  //   background-image: url('/images/global-bg.png');
  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   inset: 0;
  // }
}

@import '../public/sass/utilities';

@import 'bootstrap/scss/bootstrap';

@import '../public/sass/fonts/fonts';
@import '../public/sass/global';

$enable-dark-mode: false;
