// For erp light mode
$erp-colors-light: (
  'primary': #810019,
  'primary-2': #c98986,
  'primary-3': #e4c5c3,
);

$erp-bg-light: (
  'white': #ffffff,
  'secondary': #dce2eb,
  'erp': #f6f7f9,
  'input': #f1f1f1,
  'all-white': #ffffff,
  'all-dark': #242526,
  'overlay-secondary': rgba(220, 226, 235, 0.678),
  'overlay': #11111180,
  'skeleton': #e0e0e0,
);

$erp-text-light: (
  'dark': #414141,
  'secondary': #838383,
  'primary-1': #810019,
);

// For erp dark mode
$erp-colors-dark: (
  'primary': #810019,
  'primary-2': #c98986,
  'primary-3': #e4c5c3,
);

$erp-bg-dark: (
  'white': #242526,
  'secondary': #18191a,
  'erp': #18191a,
  'input': #3a3b3c,
  'all-white': #ffffff,
  'all-dark': #242526,
  'overlay-secondary': #18191a94,
  'overlay': #12121280,
  'skeleton': #e0e0e047,
);

$erp-text-dark: (
  'dark': #d9d9d9,
  'secondary': #9d9d9d,
  'primary-1': #810019,
  'primary': #ff6c6a,
);

html[data-bs-theme='erp-light'] {
  @each $color, $value in $erp-colors-light {
    @include makeAsGlobalColors($color, $value);
  }

  @each $color, $value in $erp-bg-light {
    @include generateBackgroundClass($color, $value);
  }

  @each $color, $value in $erp-text-light {
    @include generateTextClass($color, $value);
  }

  body,
  input,
  textarea,
  select,
  a {
    // Default color;
    color: map-get($erp-text-light, 'dark');
  }
}

html[data-bs-theme='erp-dark'] {
  @each $color, $value in $erp-colors-dark {
    @include makeAsGlobalColors($color, $value);
  }

  @each $color, $value in $erp-bg-dark {
    @include generateBackgroundClass($color, $value);
  }

  @each $color, $value in $erp-text-dark {
    @include generateTextClass($color, $value);
  }

  // Default color;
  @extend .text-dark;

  body,
  input,
  textarea,
  select,
  a {
    // Default color;
    color: map-get($erp-text-dark, 'dark');
  }

  // reset border in dark
  --bs-border-color: #474747;
}

html[data-bs-theme='erp-dark'],
html[data-bs-theme='erp-light'] {
  // Form inputs bootstrap
  .form-control,
  .form-select,
  .input-group {
    @extend .bg-input;
    &:focus,
    &:focus-visible {
      border-color: #c989866e;
      outline: 0;
      box-shadow: none;
    }
  }

  .form-check {
    .form-check-input {
      @extend .bg-input;
      &:focus,
      &:checked {
        border: none;
        box-shadow: none;
      }
      &:checked {
        @extend .bg-primary;
      }
    }
  }

  // .form-check.form-switch {

  // }

  .modal-dialog {
    height: 100vh;
    max-height: 100vh;
    min-width: 732px;
    max-width: 1247px;
    width: 100%;
    margin: 0;
    position: fixed;
    right: -75vw;
    top: 0;
    transition: transform 0.5s ease-in-out;
    overflow-y: auto;
  }
  .modal-content {
    height: 100%;
    border-radius: 0;
  }

  .modal.fade .modal-dialog {
    transform: translateX(0);
  }

  .modal.fade.show .modal-dialog {
    transform: translateX(-75vw);
  }
}
