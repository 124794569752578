@font-face {
  font-family: 'calibri';
  src: url('./calibri/calibri-regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'calibri';
  src: url('./calibri/calibri-bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'montserrat';
  src: url('./Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'montserrat';
  src: url('./Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}
