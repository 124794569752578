// For ihela light mode
$ihela-colors-light: (
  'primary': #810019,
  'primary-2': #c98986,
  'primary-3': #e4c5c3,
);

$ihela-bg-light: (
  'white': #ffffff,
  'secondary': #ebebeb,
  'ihela': #ffffff,
  'input': #f1f1f1,
  'all-white': #ffffff,
  'all-dark': #242526,
  'overlay-secondary': rgba(235, 235, 235, 0.678),
  'overlay': #11111180,
  'skeleton': #e0e0e0,
);

$ihela-text-light: (
  'dark': #414141,
  'secondary': #838383,
  'primary-1': #810019,
);

// For ihela dark mode
$ihela-colors-dark: (
  'primary': #810019,
  'primary-2': #c98986,
  'primary-3': #e4c5c3,
);

$ihela-bg-dark: (
  'white': #242526,
  'secondary': #424242,
  'ihela': #242526,
  'input': #1c1c1c,
  'all-white': #ffffff,
  'all-dark': #242526,
  'overlay-secondary': #42424294,
  'overlay': #11111180,
  'skeleton': #e0e0e047,
);

$ihela-text-dark: (
  'dark': #d9d9d9,
  'secondary': #9d9d9d,
  'primary-1': #810019,
  'primary': #ff6c6a,
);

html[data-bs-theme='ihela-light'] {
  @each $color, $value in $ihela-colors-light {
    @include makeAsGlobalColors($color, $value);
  }

  @each $color, $value in $ihela-bg-light {
    @include generateBackgroundClass($color, $value);
  }

  @each $color, $value in $ihela-text-light {
    @include generateTextClass($color, $value);
  }

  body,
  input,
  textarea,
  select,
  a {
    color: map-get($ihela-text-light, 'dark');
  }
}

html[data-bs-theme='ihela-dark'] {
  @each $color, $value in $ihela-colors-dark {
    @include makeAsGlobalColors($color, $value);
  }

  @each $color, $value in $ihela-bg-dark {
    @include generateBackgroundClass($color, $value);
  }

  @each $color, $value in $ihela-text-dark {
    @include generateTextClass($color, $value);
  }

  body,
  input,
  textarea,
  select,
  a {
    // Default color;
    color: map-get($ihela-text-dark, 'dark');
  }

  // reset border in dark
  --bs-border-color: #474747;
}

html[data-bs-theme='ihela-dark'],
html[data-bs-theme='ihela-light'] {
  // Form inputs bootstrap
  .form-control,
  .form-select,
  .input-group {
    @extend .bg-input;
    &:focus,
    &:focus-visible {
      border-color: #c989866e;
      outline: 0;
      box-shadow: none;
    }
  }

  .form-check {
    .form-check-input {
      @extend .bg-input;
      &:focus,
      &:checked {
        border: none;
        box-shadow: none;
      }
      &:checked {
        @extend .bg-primary;
      }
    }
  }

  // .form-check.form-switch {

  // }
}
