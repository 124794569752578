@import './mixins/mixins';
@import 'bootstrap/scss/variables';
@import './animation';

.app-container {
  @media screen and (min-width: 1700px) {
    margin: 0 auto;
    max-width: 1700px;
  }
}
.layout-height {
  height: 100dvh;
}
.h-without-header {
  height: calc(100dvh - 60px);
}
.statement-dialog {
  min-width: 70%;
}
.w-max-content {
  width: max-content;
}

// global inchanges color
$globalColors: (
  'success': #058519,
  'info': #2070b8,
  'danger': #a11717,
  'warning': #a3a300,
);

@each $color-name, $value in $globalColors {
  @include makeAsGlobalColors($color-name, $value);
}

// global font-sizes
$fontSizes: (
  'xx-large': 35px,
  'x-large': 28px,
  'large': 24px,
  'l-medium': 20px,
  'medium': 18px,
  'normal': 16px,
  'l-small': 15px,
  'small': 14px,
  'x-small': 12px,
);
@each $name, $size in $fontSizes {
  @include generateSizeClass($name, $size);
}

// NOTE :: global color
$globalTextColors: (
  'all-white': #ffffff,
  'all-dark': #414141,
);
@each $name, $value in $globalTextColors {
  @include generateTextClass($name, $value);
  @include generateBgHoverClass($name, $value);
}

// NOTE :: global border color
:root {
  --bs-border-color: #c4c4c4;
}

// NOTE :: BOOTSTRAP SPINNER SPEED
.spinner-border {
  --bs-spinner-animation-speed: 0.4s;
}

// NOTE :: reset link
a {
  text-decoration: none !important;
}

// NOTE :: global empty state color
.empty-state {
  color: #c4c4c4;
}
// NOTE :: global border radius
.xx-lg-radius {
  border-radius: 20px;
}
.x-lg-radius {
  border-radius: 15px;
}
.lg-radius {
  border-radius: 10px;
}
.sm-radius {
  border-radius: 5px;
}
.x-sm-radius {
  border-radius: 3px;
}

//global image class
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

//global cursor class
.clickable {
  cursor: pointer;
  pointer-events: auto;
  opacity: 1;
}

.not-clickable {
  pointer-events: none;
  opacity: 0.5;
}

// font family global class
.font-calibri {
  font-family: calibri !important;
}
.font-montserrat {
  font-family: montserrat !important;
}

//global badge classes
.badge-design {
  min-height: 31px;
  max-width: 120px;
  @extend .sm-radius;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  @extend .fw-bold;
  @extend .px-2;
  @extend .fs-small;
  &.bg-success,
  &.success {
    background-color: #cefdd5 !important;
    color: #058519 !important;
  }
  &.bg-info,
  &.info {
    background-color: #aacef5 !important;
    color: #235ea2 !important;
  }
  &.bg-warning,
  &.warning {
    background-color: #fbfbad !important;
    color: #a3a300 !important;
  }
  &.bg-danger,
  &.danger,
  &.bg-failed {
    background-color: #ffcccc !important;
    color: #a11717 !important;
  }
  &.bg-secondary,
  .secondary {
    @extend .bg-secondary;
    color: #ffff !important;
  }
}

//NOTE :: GLOBAL CONFIG FOR INPUTS
.form-control,
.form-select,
.input-group {
  @extend .border;
  @extend .text-dark;
  @extend .sm-radius;
}

.form-control + label,
.form-select + label {
  @extend .form-label;
  @extend .text-dark;
  &:after {
    background-color: transparent !important;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding-bottom: 0;
}
input.form-control::placeholder {
  @extend .text-secondary;
}
.form-floating input.form-control::placeholder {
  opacity: 0;
}

.input-group {
  @extend .sm-radius;
  overflow: hidden;
  .form-control {
    border: 0 !important;
    &:focus {
      box-shadow: none !important;
    }
  }
  .input-group-text {
    background-color: inherit;
    @extend .text-dark;
    border: 0 !important;
    border-radius: 0 !important;
  }
}

dialog::backdrop {
  background: rgba(17, 17, 17, 0.5);
}
dialog {
  z-index: -1;
  opacity: 0;
}
dialog[open] {
  animation: show 0.5s ease normal;
  opacity: 1;
  background: none;
}

@keyframes show {
  from {
    scale: 1.1;
    opacity: 0.5;
    transform: translateY(-50%);
  }
  to {
    scale: 1;
    opacity: 1;
    transform: translateY(0%);
  }
}

.myModal {
  position: fixed;
  inset: 0;
  z-index: 60;

  .modal-overlay {
    width: 100%;
    height: 100%;
    background: rgba(17, 17, 17, 0.5);
    z-index: 20;
    position: relative;
  }

  // NOTE :: REMOVE MODAL CONTECT AFTER CHECK COMPATIBILITY
  .modal-content,
  .modal-body {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 40;
    width: auto;
  }
}

// NOTE :: SCROLL BAR CUTOM

* {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &:hover {
    scrollbar-color: var(--bs-border-color) transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 10px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: var(--bs-border-color);
  }
}

@import './colors/erp-theme';
@import './colors/ihela-theme';
@import './colors/magis-theme';
@import './colors/onamob-theme';
